
<template>
  <div id="page-user-rdit">
    <div v-if="!isLoading">
      <div class="row">
        <div class="col-lg-4 order-lg-2 col-xl-4">
          <div class="card">
            <div class="card-body text-center">
              <img :src="absoluteUrl(shownUser.image)" class="rounded-circle avatar-xl img-thumbnail"
                alt="profile-image" />
              <h4 class="mt-3 mb-0" v-if="shownUser.role == 'buyer'">{{shownUser.first_name}} {{shownUser.last_name}}</h4>
              <h4 class="mt-3 mb-0" v-if="shownUser.role == 'worker'">{{shownUser.username}}</h4>
              <p class="text-muted">Type: {{shownUser.role}}</p>
              <button type="button" class="btn mr-1 btn-success btn-xs waves-effect mb-2 waves-light">
                {{shownUser.status}}
              </button>
              <span>
              <button type="button" class="btn btn-xs waves-effect mb-2 waves-light" 
                :class="shownUser.is_verified ? 'btn-info' : 'btn-danger'">
                {{shownUser.is_verified ? 'Verified' : 'Not Verified'}}
              </button>
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-xl-8">
          <div class="card">
            <div class="card-body">
              <b-tabs content-class="mt-1" pills class="navtab-bg">
                <b-tab active>
                  <template v-slot:title>
                    <i class="mdi mdi-account-check mr-1"></i> Account
                  </template>
                  <user-edit-tab-account />
                </b-tab>
                <b-tab>
                  <template v-slot:title>
                    <i class="mdi mdi-book-open-outline mr-1"></i> Info
                  </template>
                  <user-edit-tab-information />
                </b-tab>
                <b-tab>
                  <template v-slot:title>
                    <i class="mdi mdi-lock-check mr-1"></i> Change Password
                  </template>
                  <user-edit-tab-password />
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <is-loading v-else />
  </div>
</template>

<script>

import IsLoading from "@/components/IsLoading.vue"
import UserEditTabAccount     from "./UserEditTabAccount.vue"
import UserEditTabInformation from "./UserEditTabInformation.vue"
import UserEditTabPassword from "./UserEditTabPassword.vue"

export default {
  components: {
    IsLoading,
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabPassword,
  },
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    shownUser(){
      return this.$store.state.userList.user
    }
  },
  created() {
    this.$store.dispatch("userList/fetchRoles")
    this.$store.dispatch("userList/fetchUser", this.$route.params.userId)
    .then((response) => {
      if(response.data.success){
        this.isLoading = false
      }
    })
  }
}

</script>
