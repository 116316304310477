<template>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label for="new-password">New Password</label>
        <input  v-model="data.password" id="new-password"  ref="password" type="password"
        class="form-control" v-validate="'required|min:4|max:100'" placeholder="Enter new password" name="password" />
        <span class="text-danger font-sm" v-show="errors.has('password')">{{ errors.first('password') }}</span>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="confirm-password">Confirm Password</label>
        <input v-model="data.password_confirmation" id="confirm-password" type="password"
        class="form-control" v-validate="'required|min:4|max:100|confirmed:password'" data-vv-as="password" placeholder="Confirm Password" name="confirm_password"  />
        <span class="text-danger font-sm" v-show="errors.has('confirm_password')">{{ errors.first('confirm_password') }}</span>
      </div>
    </div>
    <div class="col-12">
      <div class="text-right">
        <button type="button" @click.prevent="updateUser" class="btn btn-success mt-2">
          <i class="mdi mdi-content-save"></i> Update
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        password: "",
        password_confirmation: "",
      }
    }
  },
  methods:{
    updateUser() {
      this.$validator.validateAll().then(result => {
        if (!result){ this.alertError("Form Not Valid"); return;}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
         this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/users/${this.$route.params.userId}/update-password`,formData)
        .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.resetForm()
            }
        })
      });
    },
    resetForm() {
      this.data.password = this.data.password_confirmation = "";
      this.$validator.reset()
    },
  },
}
</script>
